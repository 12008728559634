.page {
  background-color: #3b444b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #f5f5f5;
}

.emoji {
  font-size: 6rem;
}

.pulsate {
  animation: pulsate 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
  text-align: center;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
